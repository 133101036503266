import React, { useState, useRef, useEffect, useMemo } from "react";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import {
  isDoorType,
  isWindowType,
  WallType,
  SymbolType,
  isDoubleDoorType,
  isCircleStairsType,
  isRectStairsType,
  isSvgType,
  isTextType,
  DoorType,
  DoubleDoorType,
  WindowType,
  RectStairsType,
  SvgType,
  isSquareSymbolType,
  SquareSymbolType,
  isCircleSymbolType,
  isTriangleSymbolType,
  TriangleSymbolType,
  CircleSymbolType,
  SingleLineType,
  isSymbolType,
  RulerLineType,
  isRulerLineType,
  WallConnectionEnd,
  WallConnectionStart,
  isAreaSymbolType,
} from "../../types/wallTypes"; // Adjust the import path as needed
import * as THREE from "three";
import { floorplannerStore } from "../../store/floorplannerStore";
import { HiOutlinePlus } from "react-icons/hi";
import { useFloorplansQuery } from "../../gql";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { CgImage } from "react-icons/cg";
import { FiDownload } from "react-icons/fi";
import { LuCopy } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { GrDocumentImage } from "react-icons/gr";
import { PiAngle } from "react-icons/pi";
import { convertWorldToMillimeter, convertMillimeterToWorld } from '../../utils/conversions';
import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
  closestCenter,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { invalidate, render } from '@react-three/fiber';
import { PiFlipHorizontalFill } from "react-icons/pi";
import { PiFlipVerticalFill } from "react-icons/pi";
import { GiAnticlockwiseRotation } from "react-icons/gi";
import { Tooltip } from "flowbite-react";
import { CircleStairsType } from '../../types/wallTypes';
import lock from '../../assets/Proportional1.png'
import unlocked from '../../assets/Unproportional1.png'
import { renderStore } from "../../store/renderStore";
import { object, symbol } from "prop-types";
import { PiLink } from "react-icons/pi";
import { PiLinkBreak } from "react-icons/pi";
import Clarity from "@microsoft/clarity";
import { set } from "mobx";
import { updateWallConnections } from "../FloorPlan/updateWallConnections";
import ConfirmDeletePopup from "./ConfirmDeletePopup";
import CustomColorPicker from "./CustomColorPicker";


// Sortable Item Component with Tailwind CSS styling and click/drag handling
const SortableItem: React.FC<{
  id: string;
  label: string;
  icon: JSX.Element | null;
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
}> = ({
  id,
  label,
  icon,
  isSelected,
  onClick,
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const [isDragging, setIsDragging] = useState(false);

    // Tailwind CSS classes for styling
    const classNames = [
      'block',
      'cursor-pointer',
      'flex',
      'items-center',
      'justify-start',
      'p-2', // Padding inside the row
      'ml-1 mr-1 mb-[1px]', // Margin between rows
      'rounded-md', // Rounded corners for the selected row
      'transition-transform',
      'transform', // Enable transform
      'duration-200', // Smooth animation for drag/drop
      isSelected ? 'bg-[#E5EAFF] border border-none' : 'bg-transparent', // Highlight selected rows
      'hover:bg-indigo-50', // Hover effect for non-selected rows
    ].join(' ');

    // Handle onMouseDown (start of drag)
    const handleMouseDown = () => {
      setIsDragging(false); // Reset dragging state
    };

    // Handle onMouseUp (end of drag or click)
    const handleMouseUp = (e: React.MouseEvent) => {
      if (!isDragging) {
        onClick(e); // Only fire click if dragging did not happen
      }
    };

    // Handle dragging event
    const handleDragStart = () => {
      setIsDragging(true); // Mark as dragging
    };

    return (
      <div
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          transition,
          touchAction: 'none',
        }}
        {...attributes}
        {...listeners}
        className={classNames}
        onMouseDown={handleMouseDown} // Handle when drag starts
        onMouseUp={handleMouseUp} // Handle when drag ends or click happens
        onDragStart={handleDragStart} // Mark dragging state
      >
        <div className="flex items-center justify-center h-5 w-5 mr-2">
          {icon}
        </div>
        <div>{label}</div>
      </div>
    );
  };

const PropertyPanel: React.FC = observer(() => {
  const [optionMenuOpen, setOptionMenuOpen] = useState(false);
  const optionMenuRef = useRef<HTMLDivElement | null>(null);
  const [floorplans, setFloorplans] = useState<any[]>([]);
  const [linePropsOpen, setLinePropsOpen] = useState(false);
  const [fillPropsOpen, setFillPropsOpen] = useState(false);
  const [otherPropsOpen, setOtherPropsOpen] = useState(false);
  const [layerPropsOpen, setLayerPropsOpen] = useState(true);
  const lineTypeOptions = ["solid", "dashed"];
  const [wallLength, setWallLength] = useState(0);
  const [rulerLength, setRulerLength] = useState(0);
  const [singleLineLength, setSingleLineLength] = useState(0);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  // Check if any of the selected symbols are of text type
  const isAnyTextSelected = editorStore.selections.some((object) =>
    isTextType(floorplannerStore.symbolsMap.get(object.id))
  );
  /**
   * Local state for form fields, it is used while editing so that the global state is not updated
   * until the user clicks outside the form field or presses Enter.
   * This is to prevent the form field from updating the global state on every key press.
   * */
  const [localValues, setLocalValues] = useState<any>({});

  const { data } = useFloorplansQuery({
    variables: {},
  });
  
  const [symbolOrder, setSymbolOrder] = useState(Array.from(floorplannerStore.symbolsMap.keys()));
  const [singleLinesOrder, setSingleLinesOrder] = useState(Object.keys(floorplannerStore.singleLinesMap));
  const sensors = useSensors(useSensor(PointerSensor));

  // Function to handle drag end event
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setSymbolOrder((items) => {
        const oldIndex = items.indexOf(String(active.id));
        const newIndex = over ? items.indexOf(String(over.id)) : oldIndex;
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  // Determine if the symbol is selected by checking editorStore.selections
  const isSymbolSelected = (symbolId: string) => {
    return editorStore.selections.some((selection) => {
      return selection.id === symbolId; // Adjust this based on the actual structure of your selection
    });
  };

  // Generate labels based on symbol type
  const symbolsWithLabels = useMemo(() => {
    const typeCounts: { [key: string]: number } = {};

    return symbolOrder.map((symbolId, index) => {
      const symbol = floorplannerStore.symbolsMap.get(symbolId);
      let symbolTypeLabel = '';

      if (isDoorType(symbol)) {
        symbolTypeLabel = 'Door';
      } else if (isDoubleDoorType(symbol)) {
        symbolTypeLabel = 'Double Door';
      } else if (isCircleStairsType(symbol)) {
        symbolTypeLabel = 'Circular Stairs';
      } else if (isRectStairsType(symbol)) {
        symbolTypeLabel = 'Rectangular Stairs';
      } else if (isSquareSymbolType(symbol)) {
        symbolTypeLabel = 'Square';
      }
      else if (isCircleSymbolType(symbol)) {
        symbolTypeLabel = 'Cricle';
      }
      else if (isTriangleSymbolType(symbol)) {
        symbolTypeLabel = 'Triangle';
      }
      else if (isSvgType(symbol)) {
        symbolTypeLabel = 'SVG Symbol';
      }
      /*  else if (isTextType(symbol)) {
        symbolTypeLabel = 'Text Symbol';
      } */
      else if (isWindowType(symbol)) {
        symbolTypeLabel = 'Window';
      } else if (isRulerLineType(symbol)) {
        symbolTypeLabel = 'Ruler';
      } else if (isAreaSymbolType(symbol)) {
        symbolTypeLabel = 'Measure Area';
      }
      else {
        symbolTypeLabel = 'Symbol';
      }
      // Keep track of how many times this type appears
      if (!typeCounts[symbolTypeLabel]) {
        typeCounts[symbolTypeLabel] = 1;
      } else {
        typeCounts[symbolTypeLabel]++;
      }

      // Generate label with type and count
      const label = `${symbol?.name ?? symbolTypeLabel} ${typeCounts[symbolTypeLabel]}`;

      return {
        symbolId,
        label,
      };
    });
  }, [symbolOrder, floorplannerStore.symbolsMap]);

  useEffect(() => {
    setSymbolOrder(Array.from(floorplannerStore.symbolsMap.keys()));
  }, [floorplannerStore.symbolKeys]);

  useEffect(() => {
    let floorplans: React.SetStateAction<any[]> = [];
    if (data) {
      data.floorplans?.forEach((floorplan) => {
        floorplans.push({ id: floorplan?.id, name: floorplan?.name });
      });
      setFloorplans(floorplans);
    }
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionMenuRef.current && !optionMenuRef.current.contains(event.target as Node)) {
        setOptionMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionMenuRef]);

  // When global state is updated, update local state
  useEffect(() => {
    editorStore.selections.forEach((selection) => {
      if (Object.prototype.hasOwnProperty.call(selection, "type")) {
        if (selection.type === "wall") {
          //const wall = floorplannerStore.walls[selection.id];
          const wall = floorplannerStore.wallsMap.get(selection.id);
          if (!wall) return;
          setLocalValues((prevValues: any) => ({
            ...prevValues,
            [selection.id]: {
              startX: convertWorldToMillimeter(wall.start.x),
              startY: convertWorldToMillimeter(wall.start.y),
              endX: convertWorldToMillimeter(wall.end.x),
              endY: convertWorldToMillimeter(wall.end.y),
              wallWidth: convertWorldToMillimeter(wall.wallWidth || floorplannerStore.wallWidth),
            },
          }));
        } else if (["symbol", "door", "doubleDoor", "window", "circleStairs", "rectStairs", "square", "circle", "triangle", "svg"].includes(selection.type)) {
          const symbol = floorplannerStore.symbolsMap.get(selection.id);
          if (!symbol) return;
          setLocalValues((prevValues: any) => ({
            ...prevValues,
            [selection.id]: {
              positionX: convertWorldToMillimeter(symbol.position.x),
              positionY: convertWorldToMillimeter(symbol.position.y),
              rotation: symbol.rotation,
              lineWidth: symbol.lineWidth,
              flipHorizontal: symbol.flipHorizontal,
              flipVertical: symbol.flipVertical,
            },
          }));
          if (isDoorType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                doorWidth: symbol.doorWidth,
                openAngle: symbol.openAngle,
              },
            }));
          }
          if (isDoubleDoorType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                doubleDoorWidth: symbol.doubleDoorWidth,
                openAngle: symbol.openAngle,
              },
            }));
          }
          if (isCircleStairsType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                circleStairsWidth: symbol.circleStairsWidth,
                openAngle: symbol.openAngle,
                stairStepSize: symbol.stairStepSize,
              },
            }));
          }
          if (isRectStairsType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                rectStairsWidth: symbol.rectStairsWidth,
                rectStairsHeight: symbol.rectStairsHeight,
                openAngle: symbol.openAngle,
                stairStepSize: symbol.stairStepSize,
              },
            }));
          }
          if (isSquareSymbolType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                squareWidth: symbol.squareWidth,
                squareHeight: symbol.squareHeight,
                openAngle: symbol.openAngle,
              },
            }));
          }
          if (isCircleSymbolType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                circleWidth: symbol.circleWidth,
                circleHeight: symbol.circleHeight,
                openAngle: symbol.openAngle,
              },
            }));
          }
          if (isTriangleSymbolType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                triangleWidth: symbol.triangleWidth,
                triangleHeight: symbol.triangleHeight,
                openAngle: symbol.openAngle,
              },
            }));
          }
          if (isSvgType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                svgLength: symbol.svgLength,
                svgHeight: symbol.svgHeight,
                svgPath: symbol.svgPath
              },
            }));
          }
          /* if (isTextType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                textBoxWidth: symbol.textBoxWidth,
                textBoxHeight: symbol.textBoxHeight,
                fontSize: symbol.fontSize,
                fontStyle: symbol.fontStyle,
                fontWeight: symbol.fontWeight,
                lineHeight: symbol.lineHeight,
                text: symbol.text,
              },
            }));
          } */
          if (isWindowType(symbol)) {
            setLocalValues((prevValues: any) => ({
              ...prevValues,
              [selection.id]: {
                windowWidth: symbol.windowWidth,
                windowLength: symbol.windowLength,
              },
            }));
          }
        }
      }
    });
  }, [editorStore.selections, floorplannerStore.symbolsMap]);

  const updateGlobalState = (object: any, property: string, value: any) => {
    editorStore.selections.forEach((selection) => {
      if (Object.prototype.hasOwnProperty.call(selection, "type")) {
        floorplannerStore.pushToUndoStack();
        if (selection.type === "wall") {
          if (property === "lineColor" || property === "fillColor" || property === "lineWeight" || property === "lineForm" || property === "lineType") {
            floorplannerStore.setObjectProperty(selection.id, property, value);
          } else if (property === "length") {
            floorplannerStore.setWallLength(selection.id, convertMillimeterToWorld(value), editorStore.whichEndToUpdate === "start" ? WallConnectionStart : editorStore.whichEndToUpdate === "end" ? WallConnectionEnd : undefined, "inner");
            setTimeout(() => {
              floorplannerStore.objectNeedsUpdate(selection.id);
              // Also update all connected walls
              selection.connections?.forEach((connection) => {
                floorplannerStore.objectNeedsUpdate(connection.id);
              });
            }, 50);
            editorStore.setWallEditingInnerLength(undefined);
          } else {
            floorplannerStore.setObjectProperty(selection.id, property, convertMillimeterToWorld(value));
            floorplannerStore.objectNeedsUpdate(selection.id);
            // It is necessary to re-render all connected walls in between the start and end points
            selection.connections?.forEach((connection) => {
              if (connection.sourcePosition !== WallConnectionStart && connection.sourcePosition !== WallConnectionEnd) {
                setTimeout(() => {
                  renderStore.clearWallShapes(connection.id);
                  updateWallConnections(connection.id, null, [], floorplannerStore);
                  renderStore.updateShapes(connection.id);
                  floorplannerStore.objectNeedsUpdate(connection.id);
                }, 0);
              }
            });            
          }
        } else if (selection.type === "singleLine") {
          if (property === "lineColor" || property === "lineWeight" || property === "lineType") {
            floorplannerStore.updateSingleLineProp(selection.id, property, value);
          } else if (property === "length") {
            floorplannerStore.setSingleLineLength(selection.id, convertMillimeterToWorld(value), editorStore.whichEndToUpdate);
            floorplannerStore.objectNeedsUpdate(selection.id);
            editorStore.setSingleLineEditingLength(undefined);
          } else {
            floorplannerStore.updateSingleLineProp(selection.id, property as keyof SingleLineType, convertMillimeterToWorld(value));
          }
        } else if (selection.type === "rulerLine") {
          if (property === "lineColor" || property === "lineWeight" || property === "lineType") {
            floorplannerStore.updateRulerLineProp(selection.id, property, value);
          } else if (property === "length") {
            floorplannerStore.setRulerLineLength(selection.id, convertMillimeterToWorld(value), editorStore.whichEndToUpdate);
            floorplannerStore.objectNeedsUpdate(selection.id);
            editorStore.setRulerLineEditingLength(undefined);
          } else {
            floorplannerStore.updateRulerLineProp(selection.id, property as keyof RulerLineType, convertMillimeterToWorld(value));
          }
        }
        else if (["symbol", "door", "doubleDoor", "window", "circleStairs", "rectStairs", "square", "circle", "triangle", "svg"].includes(selection.type)) {
          if (property === "lineColor" || property === "fillColor" || property === "lineType") {
            floorplannerStore.updateSymbolProperty(selection.id, property, value);
          } else if (property === "length" || property === "windowWidth" || property === "circleStairsWidth"
            || property === "rectStairsWidth" || property === "rectStairsHeight" ||
            property === "squareWidth" || property === "squareHeight" ||
            property === "circleWidth" || property === "circleHeight" || property === "circleRadius" ||
            property === "triangleWidth" || property === "triangleHeight" ||
            property === "stairStepSize") {
            floorplannerStore.updateSymbolProperty(selection.id, property, convertMillimeterToWorld(value));
          }
          else if (property === "svgHeight" || property === "svgLength") {
            if (selection) {
              floorplannerStore.updateSymbolProperty(selection.id, property, convertMillimeterToWorld(value));
            }
          }
          else if (property === "positionX") {
            const orgY = floorplannerStore.symbolsMap.get(selection.id)?.position.y;
            floorplannerStore.updateSymbolProperty(selection.id, "position", [convertMillimeterToWorld(value), orgY]);
          } else if (property === "positionY") {
            const orgX = floorplannerStore.symbolsMap.get(selection.id)?.position.x;
            floorplannerStore.updateSymbolProperty(selection.id, "position", [orgX, convertMillimeterToWorld(value)]);
          } else if (property === "windowLength" || property === "doorWidth" || property === "doubleDoorWidth") {
            floorplannerStore.updateSymbolProperty(selection.id, property, convertMillimeterToWorld(value));
            const attachedWall = floorplannerStore.symbolIsAttached(selection.id);
            if (attachedWall) {
              const object = floorplannerStore.symbolsMap.get(selection.id) as WindowType | DoorType | DoubleDoorType;
              // Detach the door from the wall if it is attached
              floorplannerStore.detachSymbolFromWall(object.id);
              // Re-attach the door to the wall
              floorplannerStore.attachSymbolToWall(selection.id, attachedWall, [object.position.x, object.position.y]);
            }
          } else {
            floorplannerStore.updateSymbolProperty(selection.id, property, value);
          }
        }
      }
    });
  };

  // Convert a numeric color (0xRRGGBB) to a hex string (#RRGGBB)
  const numberToHexColor = (colorValue: number) => {
    let hexColor = colorValue.toString(16).padStart(6, '0');
    if (!hexColor.startsWith('#')) {
      hexColor = `#${hexColor}`;
    }
    return hexColor;
  };

  // Convert a hex string (#RRGGBB) to a numeric color (0xRRGGBB)
  const hexColorToNumber = (hexColor: string) => {
    // Ensure that the hexColor starts with '#'
    if (hexColor.startsWith('#')) {
      hexColor = hexColor.slice(1); // Remove the '#' before converting
    }
    return parseInt(hexColor, 16);
  };

  // Render color input for Line properties
  const renderLineColorInput = (object: SymbolType | WallType | SingleLineType | RulerLineType) => {
    if (!object) return null;
    const id = object.id;

    // Convert the numeric color to a hex string for the color picker
    const lineColor = localValues[id]?.lineColor || numberToHexColor(object.lineColor || 0);

    return (
      <div
        key={`lineColor-${id}`}
        className="flex flex-row justify-between items-start py-2"
      >
        <div className="mb-2">Color</div>
        <input
          className="border border-gray-300 rounded p-1 w-[90px]"
          type="color"
          value={lineColor}
          onChange={(e) => {
            const newColor = e.target.value; // Get the selected color from the input

            // Immediately update the global state
            updateGlobalState(object, "lineColor", newColor);
          }}
        />
      </div>
    );
  };

  // Render color input for Fill properties
  const [showPicker, setShowPicker] = useState(false);
    const [currentColor, setCurrentColor] = useState<string>(""); // track the color
    const pickerRef = useRef<HTMLDivElement | null>(null);
      // Close color picker on outside click
      useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
          if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
            setShowPicker(false);
          }
        };
    
        document.addEventListener("mousedown", handleOutsideClick);
    
        return () => {
          document.removeEventListener("mousedown", handleOutsideClick);
        };
      }, []);
      // Set initial color state
     
    
const renderFillColorInput = (
    object: SymbolType | WallType | SingleLineType | RulerLineType
  ) => {
    if (!object) return null;
  
    const id = object.id;
  
    // Convert the numeric color to a hex string for the color picker
    const initialColor =
      localValues[id]?.fillColor || numberToHexColor(object.fillColor || 0);
  
  
    const handleColorChange = (newColor: string) => {
      setCurrentColor(newColor);  // Update state when the color changes
      updateGlobalState(object, "fillColor", newColor); // Update global state
    };
  
    const handleTransparentClick = () => {
      const transparentColor = "rgba(0, 0, 0, 0)";
      handleColorChange(transparentColor);
      setShowPicker(false); // Close picker after setting transparency
    };
  
    const handleTogglePicker = () => {
      setShowPicker((prev) => !prev);
    };
  
  
    return (
      <div
        key={`fillColor-${id}`}
        className="flex flex-row justify-between items-start py-2"
      >
        <div className="mb-2">Color</div>
        <div
          className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
            fillPropsOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          {/* Toggle button for color picker */}
          <button
            className="border border-gray-300 rounded p-1 w-[90px]"
            style={{ backgroundColor: initialColor || "" }}  // Set the button background
            onClick={handleTogglePicker}
          ></button>
  
          {showPicker && (
            <div
              ref={pickerRef}
              style={{
                position: "absolute",
                zIndex: 10,
                top: linePropsOpen ? 600 : 460, 
                left: 0,
              }}
            >
              <CustomColorPicker
                color={currentColor}  // Pass current color to the picker
                onChange={handleColorChange}
                onTransparentClick={handleTransparentClick}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  


  const renderLineWeightFields = (object: WallType | SymbolType | SingleLineType | RulerLineType) => {
    if (!object) return null;
    const id = object.id;
    return (
      <div key={`wallTypeFields-${id}`}>
        <div>
          <div className="pb-2">Weight</div>
          <div className="flex flex-row items-center justify-center">
            <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
            <input
              className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="number"
              step={0.1}
              min={0.1}
              value={object.lineWeight ??
                (object.type === "wall" ? floorplannerStore.wallLineWeight :
                  object.type === "singleLine" ? floorplannerStore.singleLineWeight :
                    object.type === "rulerLine" ? floorplannerStore.rulerLineWeight :
                      object.type === "window" ? floorplannerStore.windowLineWeight :
                        object.type === "door" || object.type === "doubleDoor" ? floorplannerStore.doorLineWeight :
                          object.type === "circleStairs" || object.type === "rectStairs" || object.type === "square" || object.type === "circle" || object.type === "triangle" ? floorplannerStore.symbolLineWeight :
                            object.type === "svg" ? floorplannerStore.symbolLineWeight : floorplannerStore.lineWeight)}
              onChange={(e) => {
                // Make sure input is a number and is not negative
                const value = Math.max(0.1, e.target.value ? parseFloat(e.target.value) : 0.1);
                updateGlobalState(id, "lineWeight", value)
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const PropertyHeader: React.FC = observer(() => {
    const [rendering, setRendering] = useState(false);
    return (
      <div className="flex items-center justify-between flex-cols select-none pb-3">
        <div className="p-2">
          <div className="flex h-[34px] w-[34px] items-center justify-center gap-2 rounded-lg border border-stone-500 p-1 relative cursor-pointer">
            <IoMdInformationCircleOutline style={{ strokeWidth: 2 }} size={19} />
          </div>
        </div>
        <div className="p-3 flex flex-row">
          <div
            className={`flex h-[34px] w-[34px] items-center justify-center gap-2 rounded-lg border border-stone-500 p-1 relative mr-2 cursor-pointer ${optionMenuOpen ? "bg-black text-white" : ""
              }`}
            onClick={() => setOptionMenuOpen(!optionMenuOpen)}
            ref={optionMenuRef}
          >
            <div className="text-zinc-950 font-['DM Sans'] pb-1 text-center text-xl font-normal">
              ···
            </div>
            {optionMenuOpen && (
              <div className="fixed right-20 top-16 w-auto bg-black text-white border border-gray-300 shadow-lg rounded-lg py-2 z-20">
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => {
                    editorStore.clearSelections();
                    editorStore.openAddToPresentationPopup();
                  }}
                >
                  <CgImage className="text-xl transform scale-90" />
                  Add to presentation
                </div>
                <div className="border-b border-[#66645F]" />
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => {
                    editorStore.clearSelections();
                    editorStore.openDownloadProjectPopup();
                  }}
                >
                  <FiDownload className="text-xl transform scale-90" />
                  Download project
                </div>
                {/* <div className="border-b border-[#66645F]" />
                {floorplans.map((floorplan) => (
                  <div
                    key={floorplan.id}
                    className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                    onClick={() => {
                      window.location.href = `/floorplan/${floorplan.id}`;
                      setOptionMenuOpen(false);
                    }}
                  >
                    <GrDocumentImage className="text-xl transform scale-90" />
                    {floorplan.name} ({floorplan.id})
                  </div>
                ))} */}
                <div className="border-b border-[#66645F]" />
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => {
                    Clarity.event("new_project");
                    window.location.href = "/floorplan/";
                  }}
                >
                  <HiOutlinePlus className="text-xl transform scale-90" />
                  Create a new project
                </div>
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => {
                    Clarity.event("duplicate_project");
                    floorplannerStore.duplicateProject();
                   }}
                >
                  <LuCopy className="text-xl transform scale-90" />
                  Copy project
                </div>
                <div
                  className="px-4 py-2 cursor-pointer flex items-start gap-2 flex-row hover:bg-[#66645F]"
                  onClick={() => {
                    // Open the delete confirmation popup
                setIsDeletePopupOpen(true);
                    //floorplannerStore.deleteFromCloud(floorplannerStore.id);
                    setOptionMenuOpen(false);
                  }}
                >
                  <RiDeleteBinLine className="text-xl transform scale-90" />
                  Delete project
                </div>
              </div>
            )}
          </div>
          <div
            className={
              "flex h-[34px] items-center justify-center gap-2 rounded-lg p-2.5 cursor-pointer" +
              (floorplannerStore.dirty ? " bg-blue-600 text-white" : " bg-eggshell text-gray-900")
            }
          >
            <div
              className="font-['DM Sans'] text-center text-sm font-normal"
              onClick={() => {
                setRendering(true);
                renderStore.captureSnapshot('upload');
                floorplannerStore.saveToCloud();
                setTimeout(() => {
                  setRendering(false);
                }, 200);
              }}
            >
              {rendering && (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900" />
                </div>
              )}
              {!rendering && (
                <div className="flex items-center justify-center">
                  {floorplannerStore.dirty ? "Save" : "Saved"}
                </div>
              )}
            </div>
          </div>
          {/* Delete Confirmation Popup */}
      {isDeletePopupOpen && (
        <ConfirmDeletePopup
          onConfirm={() => {
            floorplannerStore.deleteFromCloud(floorplannerStore.id);
            setIsDeletePopupOpen(false);
          }}
          onCancel={() => setIsDeletePopupOpen(false)}
        />
      )}
        </div>
      </div>
    );
  });

  // When editorStore.selections is updated, update the wallLength, singleWallLength or rulerLength state
  useEffect(() => {
    if (editorStore.selections.length === 1 && editorStore.selections[0].type === "wall") {
      const wall = floorplannerStore.wallsMap.get(editorStore.selections[0].id) as WallType;
      if (wall) {
        const input = document.getElementById("wallLength") as HTMLInputElement;
        setWallLength(convertWorldToMillimeter(floorplannerStore.wallInnerLength(wall)));
        //input?.focus();
      }
    } else if (editorStore.selections.length === 1 && editorStore.selections[0].type === "rulerLine") {
      const rulerLine = floorplannerStore.rulerLinesMap.get(editorStore.selections[0].id) as RulerLineType;
      if (rulerLine) {
        const input = document.getElementById("rulerLength") as HTMLInputElement;
        setRulerLength(convertWorldToMillimeter(floorplannerStore.rulerLineLength(rulerLine.id)));
        //input?.focus();
      }
    } else if (editorStore.selections.length === 1 && editorStore.selections[0].type === "singleLine") {
      const singleLine = floorplannerStore.singleLinesMap.get(editorStore.selections[0].id) as SingleLineType;
      if (singleLine) {
        const input = document.getElementById("singleLineLength") as HTMLInputElement;
        setSingleLineLength(convertWorldToMillimeter(floorplannerStore.singleLineLength(singleLine.id)));
        //input?.focus();
      }
    }
  }, [editorStore.selections]);

  // When editorStore.editingWallLengthPanel is set, set focus to the input field id="wallLength"
  useEffect(() => {
    if (editorStore.wallEditingLengthPanel) {
      if (editorStore.selections.length === 1 && editorStore.selections[0].type === "wall") {
        const wall = floorplannerStore.wallsMap.get(editorStore.selections[0].id) as WallType;
        if (wall) {
          // When the wall.outerLength is updated, update the input field value id="wallLength"
          const input = document.getElementById("wallLength") as HTMLInputElement;
          if (input) {
            setWallLength(convertWorldToMillimeter(floorplannerStore.wallInnerLength(wall)));
            input.focus();
            setTimeout(() => {
              input.select();
            }, 0);
          }
        }
      }
    }
  }, [editorStore.wallEditingLengthPanel]);

  // When editorStore.editingSingleLineLengthPanel is set, set focus to the input field id="singleLineLength"
  useEffect(() => {
    if (editorStore.singleLineEditingLengthPanel) {
      if (editorStore.selections.length === 1 && editorStore.selections[0].type === "singleLine") {
        const singleLine = floorplannerStore.singleLinesMap.get(editorStore.selections[0].id) as SingleLineType;
        if (singleLine) {
          // When the wall.outerLength is updated, update the input field value id="singleLineLength"
          const input = document.getElementById("singleLineLength") as HTMLInputElement;
          if (input) {
            setSingleLineLength(convertWorldToMillimeter(floorplannerStore.singleLineLength(singleLine.id)));
            input.focus();
            setTimeout(() => {
              input.select();
            }, 0);
          }
        }
      }
    }
  }, [editorStore.singleLineEditingLengthPanel]);

  // When editorStore.editingRulerLengthPanel is set, set focus to the input field id="rulerLength"
  useEffect(() => {
    if (editorStore.rulerEditingLengthPanel) {
      if (editorStore.selections.length === 1 && editorStore.selections[0].type === "rulerLine") {
        const rulerLine = floorplannerStore.rulerLinesMap.get(editorStore.selections[0].id) as RulerLineType;
        if (rulerLine) {
          // When the wall.outerLength is updated, update the input field value id="rulerLength"
          const input = document.getElementById("rulerLength") as HTMLInputElement;
          if (input) {
            setRulerLength(convertWorldToMillimeter(floorplannerStore.rulerLineLength(rulerLine.id)));
            input.focus();
            setTimeout(() => {
              input.select();
            }, 0);
          }
        }
      }
    }
  }, [editorStore.rulerEditingLengthPanel]);

  return (
    <>
      <PropertyHeader />
      {!editorStore.minimized && (
        <>
          {editorStore.selections.filter((selection) => selection.type === "wall").map((selectedWall) => {
            const wall = floorplannerStore.wallsMap.get(selectedWall.id) as WallType;
            return (
              <div
                key={`wall-${wall?.id}`}
              >
                <div className="text-sm font-normal p-3">
                  <div className="flex items-center justify-between">
                    <div className="">Wall component</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      <input
                        id="wallLength"
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={wallLength}
                        onChange={(e) => {
                          let value
                          // Check that the value is a number and is not negative
                          if (isNaN(parseFloat(e.target.value)) || parseFloat(e.target.value) < 0) {
                            value = ""
                          } else {
                            value = Math.max(1, e.target.value ? parseFloat(e.target.value) : 1);
                          }
                          setWallLength(() => value as number);
                          updateGlobalState(wall?.id, "length", value);
                          updateGlobalState(wall?.id, "length", value);
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            updateGlobalState(wall?.id, "length", wallLength);
                            editorStore.setWallEditingLengthPanel(undefined);
                          }
                        }}
                        // When we click on the arrows to increase or decrease the value, update global state directly
                        onClick={(e) => {
                          updateGlobalState(wall?.id, "length", wallLength);
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(
                          wall?.wallWidth || floorplannerStore.wallWidth
                        )}
                        onChange={(e) => {
                          updateGlobalState(wall?.id, "wallWidth", parseFloat(e.target.value))
                          // If there are any window attachments, update their width as well
                          wall.symbolAttachments?.forEach((attachment) => {
                            const wallWidth = convertMillimeterToWorld(parseFloat(e.target.value))
                            const window = floorplannerStore.symbolsMap.get(attachment.symbolId) as SymbolType;
                            if (isWindowType(window)) {
                              const windowWidth = wallWidth < 0.1 ? wallWidth : floorplannerStore.windowWidth;
                              floorplannerStore.updateSymbolProperty(window.id, "windowWidth", windowWidth)
                            }
                          })
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiAngle />
                      </div>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => selection.type === "singleLine").map((selectedLine) => {
            const line = floorplannerStore.singleLinesMap.get(selectedLine.id) as SingleLineType;
            if (!line) return null;
            return (
              <div
                key={`singleLine-${line.id}`}
              >
                <div className="text-sm font-normal p-3">
                  <div className="flex items-center justify-between">
                    <div className="">Single line</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      <input
                        id="singleLineLength"
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={singleLineLength}
                        onChange={(e) => {
                          let value
                          // Check that the value is a number and is not negative
                          if (isNaN(parseFloat(e.target.value)) || parseFloat(e.target.value) < 0) {
                            value = ""
                          } else {
                            value = Math.max(1, e.target.value ? parseFloat(e.target.value) : 1);
                          }
                          setSingleLineLength(value as number);
                          updateGlobalState(line?.id, "length", value);
                          updateGlobalState(line?.id, "length", value);
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            updateGlobalState(line?.id, "length", singleLineLength);
                            editorStore.setSingleLineEditingLengthPanel(undefined);
                          }
                        }}
                        // When we click on the arrows to increase or decrease the value, update global state directly
                        onClick={(e) => {
                          updateGlobalState(line?.id, "length", singleLineLength);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiAngle />
                      </div>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => selection.type === "rulerLine").map((selectedLine) => {
            const ruler = floorplannerStore.rulerLinesMap.get(selectedLine.id) as RulerLineType;
            return (
              <div
                key={`rulerLine-${ruler.id}`}
              >
                <div className="text-sm font-normal p-3">
                  <div className="flex items-center justify-between">
                    <div className="">Ruler line</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      <input
                        id="rulerLength"
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={rulerLength}
                        onChange={(e) => {
                          let value
                          // Check that the value is a number and is not negative
                          if (isNaN(parseFloat(e.target.value)) || parseFloat(e.target.value) < 0) {
                            value = ""
                          } else {
                            value = Math.max(1, e.target.value ? parseFloat(e.target.value) : 1);
                          }
                          setRulerLength(value as number);
                          updateGlobalState(ruler?.id, "length", value);
                          updateGlobalState(ruler?.id, "length", value);
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            updateGlobalState(ruler?.id, "length", rulerLength);
                            editorStore.setRulerEditingLengthPanel(undefined);
                          }
                        }}
                        // When we click on the arrows to increase or decrease the value, update global state directly
                        onClick={(e) => {
                          updateGlobalState(ruler?.id, "length", rulerLength);
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-between py-2">
                    <div className="flex flex-row items-center justify-center">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiAngle />
                      </div>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isDoorType(selection)).map((object) => {
            const door = floorplannerStore.symbolsMap.get(object.id) as DoorType;
            return (
              <div
                key={`singleDoor-${object.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Single Door</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>

                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.doorWidth)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "doorWidth", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Open Angle</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Door Angle">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <PiAngle />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={90}
                          value={
                            // Convert to degrees
                            (door.openAngle * 180) / Math.PI
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "openAngle",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((door.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pl-4 flex items-center justify-between">
                    <div className="">Door Hanging</div>
                  </div>
                  <div className="p-3 items-left py-2">
                    <div className="flex flex-row items-center pb-4">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiFlipHorizontalFill />
                      </div>
                      <input
                        className="px-1 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="checkbox"
                        checked={door.flipHorizontal}
                        onChange={(e) =>
                          updateGlobalState(door.id, "flipHorizontal", e.target.checked)
                        }
                      />&nbsp;Flip door blade
                    </div>
                    <div className="flex flex-row items-center pb-4">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiFlipVerticalFill />
                      </div>
                      <input
                        className="px-1 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="checkbox"
                        checked={door.flipVertical}
                        onChange={(e) =>
                          updateGlobalState(door.id, "flipVertical", e.target.checked)
                        }
                      />&nbsp;Flop door blade
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isDoubleDoorType(selection)).map((object) => {
            const door = floorplannerStore.symbolsMap.get(object.id) as DoubleDoorType;
            return (
              <div
                key={`doubleDoor-${object.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Double Door</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>

                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(door.doubleDoorWidth * 2)}
                        onChange={(e) =>
                          updateGlobalState(door.id, "doubleDoorWidth", parseFloat(e.target.value) / 2)
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Open Angle</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Door Angle">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <PiAngle />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={90}
                          value={
                            // Convert to degrees
                            (door.openAngle * 180) / Math.PI
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "openAngle",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((door.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(door.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pl-4 flex items-center justify-between">
                    <div className="">Door Hanging</div>
                  </div>
                  <div className="p-3 items-left py-2">
                    <div className="flex flex-row items-center pb-4">
                      <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                        <PiFlipVerticalFill />
                      </div>
                      <input
                        className="px-1 py-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="checkbox"
                        checked={door.flipVertical}
                        onChange={(e) =>
                          updateGlobalState(door.id, "flipVertical", e.target.checked)
                        }
                      />&nbsp;Flop door blade
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isWindowType(selection)).map((object) => {
            const window = floorplannerStore.symbolsMap.get(object.id) as WindowType;
            return (
              <div
                key={`window-${window.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Window</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(window.windowLength)}
                        onChange={(e) =>
                          updateGlobalState(window.id, "windowLength", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((window.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(window.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isCircleStairsType(selection)).map((object) => {
            const circleStairs = floorplannerStore.symbolsMap.get(object.id) as CircleStairsType;
            return (
              <div
                key={`circleStair-${circleStairs.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Circle Staircase</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circleStairs.circleStairsWidth)}
                        onChange={(e) =>
                          updateGlobalState(circleStairs.id, "circleStairsWidth", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Step depth</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Step depth">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">SD</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circleStairs.stairStepSize || floorplannerStore.stairStepSize)}
                        onChange={(e) =>
                          updateGlobalState(circleStairs.id, "stairStepSize", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((circleStairs.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(circleStairs.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isRectStairsType(selection)).map((object) => {
            const rectStair = floorplannerStore.symbolsMap.get(object.id) as RectStairsType;
            return (
              <div
                key={`rectStair-${rectStair.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">Rectangular Staircase</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(rectStair.rectStairsWidth)}
                        onChange={(e) =>
                          updateGlobalState(rectStair.id, "rectStairsWidth", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Step depth</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Step depth">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">SD</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(rectStair.stairStepSize || floorplannerStore.stairStepSize)}
                        onChange={(e) =>
                          updateGlobalState(rectStair.id, "stairStepSize", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((rectStair.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(rectStair.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isSquareSymbolType(selection)).map((object) => {
            const square = floorplannerStore.symbolsMap.get(object.id) as SquareSymbolType;
            return (
              <div
                key={`squareSymbol-${square.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">{object.name ?? "Square"}</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(square.squareWidth)}
                        onChange={(e) =>
                          updateGlobalState(square.id, "squareWidth", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(square.squareHeight)}
                        onChange={(e) =>
                          updateGlobalState(square.id, "squareHeight", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((square.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(square.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isCircleSymbolType(selection)).map((object) => {
            const circle = floorplannerStore.symbolsMap.get(object.id) as CircleSymbolType;
            return (
              <div
                key={`circleSymbol-${circle.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">{object.name ?? "Circle"}</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circle.circleWidth)}
                        onChange={(e) =>
                          updateGlobalState(circle.id, "circleWidth", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(circle.circleHeight)}
                        onChange={(e) =>
                          updateGlobalState(circle.id, "circleHeight", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((circle.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(circle.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isTriangleSymbolType(selection)).map((object) => {
            const triangle = floorplannerStore.symbolsMap.get(object.id) as TriangleSymbolType;
            return (
              <div
                key={`triangleSymbol-${triangle.id}`}
              >
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">{object.name ?? "Triangle"}</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(triangle.triangleWidth)}
                        onChange={(e) =>
                          updateGlobalState(triangle.id, "triangleWidth", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(triangle.triangleHeight)}
                        onChange={(e) =>
                          updateGlobalState(triangle.id, "triangleHeight", parseFloat(e.target.value))
                        }
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={
                            // Convert to degrees
                            Math.round(((triangle.rotation || 0) * 180) / Math.PI)
                          }
                          onChange={(e) =>
                            updateGlobalState(triangle.id, "rotation",
                              // Convert to radians
                              (parseFloat(e.target.value) * Math.PI) / 180
                            )
                          }
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            )
          })}
          {editorStore.selections.filter((selection) => isSvgType(selection)).map((object) => {
            const svg = floorplannerStore.symbolsMap.get(object.id) as SvgType;

            return (
              <div key={`svg-${svg.id}`}>
                <div className="border-b border-gray-300" />
                <div className="text-sm font-normal">
                  <div className="h-[32px] pl-2.5 pr-2.5 flex items-center justify-between">
                    <div className="">{object.name ?? "SVG Symbol"}</div>
                    <div className="flex flex-row items-center justify-center text-[10px]">
                      {/* <button className="px-1 py-1.5 border rounded-sm mr-2">Inch</button> */}
                      <button className="bg-[#66645F] text-white px-1 py-1.5 border rounded-sm">mm</button>
                    </div>
                  </div>
                  <div className="border-b border-gray-300" />
                  <div className="p-3 pb-1 pl-4 flex items-center justify-between">
                    <div className="">Sizing</div>
                  </div>

                  {/* Height Input */}
                  <div className="p-3 flex items-center justify-between py-2 pb-4">
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Width">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">W</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[65px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(svg.lockAspectRatio ? (svg.svgLength / (svg.svgRatio || 1)) : svg.svgHeight)}
                        onChange={(e) => {
                          const newHeight = parseFloat(e.target.value);
                          updateGlobalState(svg.id, "svgHeight", newHeight);

                          if (svg.lockAspectRatio) {
                            const newLength = newHeight * (svg.svgRatio || 1); // Maintain aspect ratio
                            updateGlobalState(svg.id, "svgLength", newLength);
                          }
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>

                    {/* Lock Aspect Ratio Toggle */}
                    <div className="flex mt-2 pr-1.5 pl-1.5 flex-row items-center justify-center">
                      <Tooltip content={svg.lockAspectRatio ? "Unlock AspectRatio" : "Lock AspectRatio"}>
                        <button
                          onClick={() => {
                            floorplannerStore.updateSymbolProperty(svg.id, "lockAspectRatio", !svg.lockAspectRatio)
                            if (svg.lockAspectRatio) {
                              const newHeight = svg.svgLength / (svg.svgRatio || 1); // Maintain aspect ratio
                              updateGlobalState(svg.id, "svgHeight", convertWorldToMillimeter(newHeight));
                            }
                          }}
                        >
                          {/* <img src={svg.lockAspectRatio ? lock : unlocked} alt="lock/unlock" /> */}
                          {svg.lockAspectRatio ? <PiLink fontSize={18} /> : <PiLinkBreak fontSize={18} />}
                        </button>
                      </Tooltip>
                    </div>

                    {/* Length Input */}
                    <div className="flex flex-row items-center justify-center">
                      <Tooltip content="Length">
                        <div className="px-1 py-2 border border-r-0 rounded-sm">L</div>
                      </Tooltip>
                      <input
                        className="px-1 py-2 w-[65px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        type="number"
                        value={convertWorldToMillimeter(svg.svgLength)}
                        onChange={(e) => {
                          const newLength = parseFloat(e.target.value);
                          updateGlobalState(svg.id, "svgLength", newLength);

                          if (svg.lockAspectRatio) {
                            const newHeight = newLength / (svg.svgRatio || 1); // Maintain aspect ratio
                            updateGlobalState(svg.id, "svgHeight", newHeight);
                          }
                        }}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                      />
                    </div>
                  </div>

                  <div className="border-b border-gray-300" />

                  {/* Rotation Control */}
                  <div className="p-3 flex items-center justify-between py-2">
                    <div>
                      <div className="py-3">Rotation</div>
                      <div className="flex flex-row items-center justify-center pb-4">
                        <Tooltip content="Rotation">
                          <div className="px-1 py-2 h-[38px] border border-r-0 rounded-sm flex items-center">
                            <GiAnticlockwiseRotation />
                          </div>
                        </Tooltip>
                        <input
                          className="px-1 py-2 w-[70px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          type="number"
                          min={0}
                          max={359}
                          value={Math.round(((svg.rotation || 0) * 180) / Math.PI)} // Convert to degrees
                          onChange={(e) =>
                            updateGlobalState(svg.id, "rotation", (parseFloat(e.target.value) * Math.PI) / 180) // Convert to radians
                          }
                          onFocus={(e) => {
                            e.target.select();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-b border-gray-300" />
              </div>
            );
          })}
          {editorStore.selections.length !== 0 && !isAnyTextSelected && (
            <>
              <div className="text-sm font-normal p-3">
                <div className="flex items-center justify-between" onClick={() => setLinePropsOpen(!linePropsOpen)}>
                  <div className="">Line</div>
                  <div className="flex flex-row items-center justify-center">
                    {linePropsOpen ? "-" : "+"}
                  </div>
                </div>
              </div>
              <div
                className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${linePropsOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
                  }`}
              >
                <div className="text-sm font-normal p-3 pt-0">
                  {editorStore.selections.filter((object) => !isTextType(floorplannerStore.symbolsMap.get(object.id)))
                    .map((object) => {
                      let currentObject
                      if (object.type === "wall") {
                        //currentObject = floorplannerStore.walls[object.id];
                        currentObject = floorplannerStore.wallsMap.get(object.id);
                      } else if (object.type === "singleLine") {
                        currentObject = floorplannerStore.singleLinesMap.get(object.id);
                      } else if (object.type === "rulerLine") {
                        currentObject = floorplannerStore.rulerLinesMap.get(object.id);
                      } else if (isSymbolType(object)) {
                        currentObject = floorplannerStore.symbolsMap.get(object.id);
                      }
                      if (!currentObject) return null;
                      return (
                        <div
                          key={`lineProps-${object.id}`}
                        >
                          <div className="flex items-center justify-between py-2 pt-0">

                            {renderLineWeightFields(currentObject)}
                            <div>
                              <div className="pb-2">Type</div>
                              <div className="flex flex-row items-center justify-center">
                                <select
                                  className="capitalize px-1 py-2 w-[90px] h-[38px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  onChange={(e) => {
                                    updateGlobalState(currentObject.id, "lineType", e.target.value)
                                  }}
                                  defaultValue={currentObject.lineType}
                                >
                                  {lineTypeOptions.map((type) => (
                                    <option
                                      key={type}
                                      value={type}
                                    >
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          {renderLineColorInput(currentObject)}
                        </div>
                      )
                    })}
                </div>
              </div>
              <div className="border-b border-gray-300" />
            </>
          )}
          {editorStore.selections.length !== 0 && !isAnyTextSelected && (
            <>
              <div className="text-sm font-normal p-3">
                <div className="flex items-center justify-between" onClick={() => setFillPropsOpen(!fillPropsOpen)}>
                  <div className="">Fill</div>
                  <div className="flex flex-row items-center justify-center">
                    {fillPropsOpen ? "-" : "+"}
                  </div>
                </div>
              </div>
              <div
                className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${fillPropsOpen ? "max-h-auto opacity-100" : "max-h-0 opacity-0"
                  }`}
              >
                <div className="text-sm font-normal p-3">
                  {editorStore.selections
                    .filter((object) => !isTextType(floorplannerStore.symbolsMap.get(object.id))) // Exclude text symbols
                    .map((object: SymbolType | WallType | SingleLineType | RulerLineType) => renderFillColorInput(object))}
                </div>
              </div>
              <div className="border-b border-gray-300" />
            </>
          )}
       {/*    <div className="text-sm font-normal p-3">
            <div className="flex items-center justify-between" onClick={() => setLayerPropsOpen(!layerPropsOpen)}>
              <div className="">Layers</div>
              <div className="flex flex-row items-center justify-center">{layerPropsOpen ? "-" : "+"}</div>
            </div>
          </div> */}
         {/*  <div
            className={`text-sm font-normal overflow-hidden transition-[max-height] duration-500 ease-in-out ${layerPropsOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
              }`}
          >
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={symbolOrder} strategy={verticalListSortingStrategy}>
                <div className="flex flex-col w-full"> // Reduced gap for smaller rows 
                  {symbolsWithLabels
                    .filter(({ symbolId }) => {
                      const symbol = floorplannerStore.symbolsMap.get(symbolId);
                      return !isTextType(symbol); // Exclude text symbols from the Layers
                    })
                    .map(({ symbolId, label }) => (
                      <SortableItem
                        key={symbolId}
                        id={symbolId}
                        label={label}
                        icon={null}
                        isSelected={isSymbolSelected(symbolId)} // Check if the symbol is selected
                        onClick={(e) => {
                          // If shift is not pressed, clear any previous selection
                          if (!e.shiftKey) {
                            editorStore.clearSelections()
                          }
                          if (isSymbolSelected(symbolId)) {
                            const symbol = floorplannerStore.symbolsMap.get(symbolId);
                            if (symbol?.type === "text") {
                              return; // Skip the selection process if the symbol is text
                            }
                            if (symbol) {
                              editorStore.removeSelection(symbol);
                            }
                            floorplannerStore.updateSymbolProperty(symbolId, "selected", false);
                          } else {
                            const symbol = floorplannerStore.symbolsMap.get(symbolId);
                            if (symbol) {
                              editorStore.addSelection(symbol);
                            }
                            floorplannerStore.updateSymbolProperty(symbolId, "selected", true);
                          }
                          invalidate()
                        }}
                      />
                    ))}
                </div>
              </SortableContext>
            </DndContext>
          </div> */}
        </>
      )}
    </>
  );
});

export default PropertyPanel;
