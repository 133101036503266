import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import * as THREE from "three";
import { invalidate, useFrame, useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { CircleStairsType, zIndexEpsilon } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Line } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";
import { set, transaction } from "mobx";
import { color } from "html2canvas/dist/types/css/types/color";

interface CircleStairsProps {
  circleStair: CircleStairsType;
  onDragStart: (circleStair: CircleStairsType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const CircleStairs: React.FC<CircleStairsProps> = observer(({
  circleStair,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const [currentOpenAngle, setCurrentOpenAngle] = useState(circleStair.openAngle);
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const attachableRef = useRef<{ checkForAttachment: () => void }>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);
  const lineWeight = circleStair.lineWeight || floorplannerStore.symbolLineWeight;
  const [circleStairsWidth, setCircleStairsWidth] = useState(circleStair.circleStairsWidth);

  const grabHandleSize = 0.12;
  const handleRadius = useMemo(() => grabHandleSize / editorStore.zoomLevelDivisor(), [editorStore.zoomLevel]);
  const handleDepth = 0.02;

  const flipX = circleStair.flipHorizontal ? -1 : 1;
  const flipY = circleStair.flipVertical ? -1 : 1;
  const hingeOffsetX = useMemo(() => (flipX === -1 ? circleStairsWidth : 0), [circleStairsWidth, flipX]);
  const lineColor = circleStair.lineColor || floorplannerStore.lineColor;
  const stairStepSize = circleStair.stairStepSize || floorplannerStore.stairStepSize;

  const openAngleGrabHandleX = useMemo(
    () => hingeOffsetX + circleStairsWidth * 0.6 * Math.cos(currentOpenAngle) * flipX,
    [hingeOffsetX, circleStairsWidth, currentOpenAngle, flipX]
  );

  const openAngleGrabHandleY = useMemo(
    () => circleStairsWidth * 0.6 * Math.sin(currentOpenAngle) * flipY,
    [circleStairsWidth, currentOpenAngle, flipY]
  );

  const [hoveredHandle, setHoveredHandle] = useState<string | null>(null);
  const isHovered = (handle: string) => hoveredHandle === handle;
  const [prevCameraZoom, setPrevCameraZoom] = useState(camera.zoom);
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const noopRaycast = () => null;
  const aboutToModify = useRef(false);


  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) return { topLeft: [0, 0], topRight: [0, 0], bottomLeft: [0, 0], bottomRight: [0, 0], width: 0, height: 0, depth: 0 };

    const tempGroup = groupRef.current.clone();
    const originalRotation = tempGroup.rotation.clone();
    tempGroup.rotation.set(0, 0, 0);

    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);
    tempGroup.rotation.copy(originalRotation);

    const topLeft = new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, 0);
    const topRight = new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, 0);
    const bottomLeft = new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, 0);
    const bottomRight = new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, 0);

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: size.x,
      height: size.y,
      depth: size.z,
    };
  }, [circleStair.position.x, circleStair.position.y, circleStairsWidth, currentOpenAngle]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = useCallback((event: PointerEvent) => {
    if (aboutToModify.current) {
      floorplannerStore.pushToUndoStack();
      aboutToModify.current = false;
    }
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dx = worldX - circleStair.position.x - dragOffset.current.x;
    const dy = worldY - circleStair.position.y - dragOffset.current.y;
    let angle = Math.atan2(dy * flipY, dx * flipX);
    angle = angle < 0 ? angle + 2 * Math.PI : angle;

    const snapAngle = (angle: number, bounds: [number, number], snapTo: number) => {
      return angle >= bounds[0] && angle <= bounds[1] ? snapTo : angle;
    };

    angle = snapAngle(angle, [(75 * Math.PI) / 180, (105 * Math.PI) / 180], Math.PI / 2);
    angle = snapAngle(angle, [(165 * Math.PI) / 180, (195 * Math.PI) / 180], Math.PI);
    angle = snapAngle(angle, [(255 * Math.PI) / 180, (285 * Math.PI) / 180], (270 * Math.PI) / 180);
    angle = snapAngle(angle, [(345 * Math.PI) / 180, Math.PI * 2], Math.PI * 2);

    setCurrentOpenAngle(angle);
    floorplannerStore.updateSymbolProperty(circleStair.id, "openAngle", angle);

  }, [circleStair.position.x, circleStair.position.y, flipX, flipY, gl, camera, floorplannerStore, circleStair.id]);

  const onPointerDownHandle = useCallback((event: React.PointerEvent) => {
    event.stopPropagation();
    aboutToModify.current = true;
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);

    dragOffset.current = {
      x: worldX - (circleStairsWidth * 0.75 * Math.cos(currentOpenAngle) * flipX + circleStair.position.x),
      y: worldY - (circleStairsWidth * 0.75 * Math.sin(currentOpenAngle) * flipY + circleStair.position.y),
    };

    gl.domElement.addEventListener("pointermove", onHandleDrag);
    gl.domElement.addEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(true);
  }, [currentOpenAngle, circleStair.position.x, circleStair.position.y, circleStairsWidth, flipX, flipY, gl, onHandleDrag, floorplannerStore]);

  const onPointerUpHandle = useCallback(() => {
    aboutToModify.current = false;
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
    calculateBoundingBox();
  }, [gl, onHandleDrag, floorplannerStore]);

  const handleDrag = useCallback((newPosition: [number, number]) => {
    onDrag(newPosition);
  }, [onDrag]);

  const generateArcPointsAndLines = useMemo(() => {
    const arcPoints = [];
    const extraLines = [];
    const maxDistance = Math.max(stairStepSize, 0.01);
    let previousPoint = null;
    let angle = 0;
    let lastLinePoint = null;
    let maxIterations = 10000;
    const arcShapePoints = [];
    while (angle <= currentOpenAngle && maxIterations > 0) {
      maxIterations--;
      const currentPoint = new THREE.Vector3(
        circleStairsWidth * Math.cos(angle) * flipX,
        circleStairsWidth * Math.sin(angle) * flipY,
        0
      );
// Collect arc points for filling the area
arcShapePoints.push(currentPoint);
      if (previousPoint) {
        const distance = currentPoint.distanceTo(previousPoint);

        if (distance > maxDistance) {
          angle -= (distance - maxDistance) / circleStairsWidth;
          continue;
        }
      }

      arcPoints.push(currentPoint);

      if (!lastLinePoint || currentPoint.distanceTo(lastLinePoint) > maxDistance) {
        extraLines.push({
          x: currentPoint.x,
          y: currentPoint.y,
          rotation: angle,
        });
        lastLinePoint = currentPoint.clone();
      }

      previousPoint = currentPoint;
      angle += 0.01;
    }
 // Close the shape by adding the center point
 arcShapePoints.push(new THREE.Vector3(circleStairsWidth, 0, 0));  // Add the center point to close the shape

    return { arcPoints, extraLines, arcShapePoints };
  }, [circleStairsWidth, stairStepSize, currentOpenAngle, flipX, flipY]);

// Convert 3D points (Vector3) to 2D points (Vector2) for the shape
const shapePoints = generateArcPointsAndLines.arcPoints.map(point => new THREE.Vector2(point.x, point.y));
// Create a new THREE.Shape
const shape = new THREE.Shape();
// Start the shape at the center
shape.moveTo(0, 0);  // Move to center of the circle

// Add the outer arc points to the shape
shapePoints.forEach((point) => {
  shape.lineTo(point.x, point.y);  // Draw line from center to the arc points
});

// Close the shape by connecting the last point to the center
shape.lineTo(0, 0);  // Back to center to close the shape

  useFrame(() => {
    if (camera.zoom !== prevCameraZoom) {
      setPrevCameraZoom(camera.zoom);
      invalidate(); // Trigger a re-render
    }
  });

  useEffect(() => {
    setCurrentOpenAngle(circleStair.openAngle);
  }, [circleStair.openAngle]);

  useEffect(() => {
    setCircleStairsWidth(circleStair.circleStairsWidth);
  }, [circleStair.circleStairsWidth]);

  useEffect(() => {
    editorStore.updateGroupRef(circleStair.id, groupRef.current);
  }, [circleStair.id, floorplannerStore]);

  return (
    <group
      position={[0, 0, (circleStair.zIndex * zIndexEpsilon)]}
    >
      <DraggableObject
        position={[circleStair.position.x, circleStair.position.y]}
        onDragStart={(offset) => {
          setIsDragging(true);
          onDragStart(circleStair, offset)
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={handleDrag}
        selectable={true}
        attachmentId={circleStair.id}
        attachmentType="doorAttachments"
        symbol={circleStair}
      >
        <SelectableSymbol
          ref={groupRef}
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResizeStart={() => setIsResizing(true)}
          onResize={(newWidth, newHeight, boundingBox, handle) => {
            const widthDiff = newWidth - circleStair.circleStairsWidth;
            transaction(() => {
              floorplannerStore.updateSymbolProperty(circleStair.id, "circleStairsWidth", newWidth);
              const position = [
                (handle === "topLeft" || handle === "bottomLeft") ? circleStair.position.x - widthDiff * Math.cos(circleStair.rotation || 0) : circleStair.position.x,
                (handle === "topLeft" || handle === "bottomLeft") ? circleStair.position.y - widthDiff * Math.sin(circleStair.rotation || 0) : circleStair.position.y,
              ]
              floorplannerStore.updateSymbolProperty(circleStair.id, "position", position);
            });

          }}
          isDragging={isDragging}
          onResizeEnd={() => setIsResizing(false)}
          center={[circleStairsWidth / 2, circleStairsWidth / 2]}
          rotation={circleStair.rotation}
          symbol={circleStair}
          onPointerOver={() => setHoveredHandle("circleStair")}
          onPointerOut={() => setHoveredHandle(null)}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, circleStair.rotation || 0]}
            onPointerEnter={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
            }}
            onPointerLeave={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
            }}
          >
            {/* Invisible box to catch events on any unfilled areas */}
             {/* Fill the area by extruding the shape */}
  <mesh position={[0, 0, -0.001]}>
  <shapeGeometry args={[shape]} />
   {/*  <meshBasicMaterial color={circleStair.fillColor} /> */}
   <meshBasicMaterial
    color={circleStair.fillColor || undefined}
    transparent={true}
    opacity={String(circleStair.fillColor) === "rgba(0, 0, 0, 0)" ? 0 : 1}
  />
  </mesh>
            <Line
              points={generateArcPointsAndLines.arcPoints.map((point) => [point.x, point.y, point.z])}
              color={hoveredHandle === "circleStair" || circleStair.selected ? "blue" : lineColor}
              lineWidth={lineWeight}
              {...(circleStair.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />

            {generateArcPointsAndLines.extraLines.map((line, index) => (
              <Line
                key={index}
                points={[
                  [0, 0, 0],
                  [line.x, line.y, 0],
                ]}
                color={hoveredHandle === "circleStair" || circleStair.selected ? "blue" : lineColor}
                lineWidth={lineWeight}
                {...(circleStair.lineType === "dashed" && {
                  dashed: true,
                  dashSize: 0.15,
                  gapSize: 0.1,
                })}
                raycast={noopRaycast}
              />
            ))}

            <Line
              points={[
                [0, 0, 0],
                [circleStairsWidth * Math.cos(currentOpenAngle) * flipX, circleStairsWidth * Math.sin(currentOpenAngle) * flipY, 0],
              ]}
              color={hoveredHandle === "circleStair" || circleStair.selected ? "blue" : lineColor}
              lineWidth={lineWeight}
              {...(circleStair.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />

            {(circleStair.selected && !isResizing && !isDragging && editorStore.selections.length === 1) && (
              <group
                onPointerDown={onPointerDownHandle as unknown as (event: THREE.Event) => void}
                onPointerUp={onPointerUpHandle as unknown as (event: THREE.Event) => void}
                onPointerEnter={(event) => {
                  event.stopPropagation();
                  document.body.classList.add("cursor-arc");
                  setHoveredHandle("circleStairHandle");
                }}
                onPointerLeave={(event) => {
                  event.stopPropagation();
                  document.body.classList.remove("cursor-arc");
                  setHoveredHandle(null);
                }}
                position={[openAngleGrabHandleX, openAngleGrabHandleY, 0]}
              >
                <mesh>
                  <torusGeometry args={[handleRadius - 0.002, 0.005, 16, 100]} />
                  <meshBasicMaterial color="blue" />
                </mesh>
                <mesh position={[0, 0, handleDepth / 2]} rotation={[Math.PI / 2, 0, 0]}>
                  <cylinderGeometry args={[handleRadius - 0.02, handleRadius - 0.006, handleDepth, 32]} />
                  <meshBasicMaterial color={isHovered("circleStairHandle") ? "blue" : "white"} />
                </mesh>
              </group>
            )}
          </group>
        </SelectableSymbol>
      </DraggableObject>
    </group>
  );
});

export default CircleStairs;
