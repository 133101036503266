import React, { useRef, useState, useEffect, useCallback } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import DraggableObject from "./DraggableObject";
import { CircleSymbolType, zIndexEpsilon } from "../../types/wallTypes";
import { SelectableSymbol, BoundingBox } from "./SelectableSymbol";
import { projectToWorld } from "./projectToWorld";
import { FloorplannerStoreContext } from "../../store/floorplannerStore";
import { Line } from "@react-three/drei";
import { editorStore } from "../../store/editorStore";
import { observer } from "mobx-react-lite";

interface CircleSymbolProps {
  circle: CircleSymbolType;
  onDragStart: (circle: CircleSymbolType, offset: [number, number]) => void;
  onDrag: (newPosition: [number, number]) => void;
  onDragEnd: (endPosition: [number, number]) => void;
}

const CircleSymbol: React.FC<CircleSymbolProps> = observer(({
  circle,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const { gl, camera } = useThree();
  const groupRef = useRef<THREE.Group>(null);
  const floorplannerStore = React.useContext(FloorplannerStoreContext);

  const lineWeight = circle.lineWeight || floorplannerStore.symbolLineWeight;
  const circleWidth = circle.circleWidth;
  const circleHeight = circle.circleHeight;

  const grabHandleSize = 0.1; // Variable to control the size of the grab handle
  const flipX = circle.flipHorizontal ? -1 : 1; // Flip horizontally
  const flipY = circle.flipVertical ? -1 : 1; // Flip vertically
  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    circleWidth / 2,
    circleHeight / 2,
  ]);
  const initialWidth = useRef(circle.circleWidth); // Store the initial width
  const initialHeight = useRef(circle.circleHeight); // Store the initial height
  const lineColor = circle.lineColor || floorplannerStore.lineColor;
  // State to store the pivot dynamically
  const [pivot, setPivot] = useState<[number, number]>([
    -circleWidth / 2,
    -circleHeight / 2,
  ]);
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const noopRaycast = () => null;

  // Calculate the bounding box of the stairs
  const calculateBoundingBox = useCallback((): BoundingBox => {
    if (!groupRef.current) {
      return {
        topLeft: [0, 0],
        topRight: [0, 0],
        bottomLeft: [0, 0],
        bottomRight: [0, 0],
        width: 0,
        height: 0,
        depth: 0,
      };
    }

    // Clone the object
    const tempGroup = groupRef.current.clone();

    // Save the original rotation
    const originalRotation = tempGroup.rotation.clone();

    // Temporarily reset the rotation to 0
    tempGroup.rotation.set(0, 0, 0);

    // Calculate the bounding box as if the object had no rotation
    const boundingBox = new THREE.Box3().setFromObject(tempGroup);
    const size = new THREE.Vector3();
    boundingBox.getSize(size);

    // Restore the original rotation
    tempGroup.rotation.copy(originalRotation);

    // Calculate the corners of the bounding box
    const topLeft = new THREE.Vector3(
      0,
      size.y + (lineWeight / 100),
      0
    );
    const topRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      size.y + (lineWeight / 100),
      0
    );
    const bottomLeft = new THREE.Vector3(
      0,
      0,
      0
    );
    const bottomRight = new THREE.Vector3(
      size.x + (lineWeight / 100),
      0,
      0
    );

    const width = topRight.x - topLeft.x;
    const height = topLeft.y - bottomLeft.y;
    setPivot([-width / 2, -height / 2]);
    centerPosition[0] = width / 2;
    centerPosition[1] = height / 2;

    return {
      topLeft: [topLeft.x, topLeft.y],
      topRight: [topRight.x, topRight.y],
      bottomLeft: [bottomLeft.x, bottomLeft.y],
      bottomRight: [bottomRight.x, bottomRight.y],
      width: boundingBox.max.x - boundingBox.min.x,
      height: boundingBox.max.y - boundingBox.min.y,
      depth: 0,
    };
  }, [circle.position.x, circle.position.y, circle.flipHorizontal, circle.flipVertical, circleWidth, circleHeight]);

  const dragOffset = useRef({ x: 0, y: 0 });

  const onHandleDrag = (event: PointerEvent) => {
    const [worldX, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - circle.position.y - dragOffset.current.y;

    // Update the handle's Y position
    setCenterPosition([circle.position.x, circle.position.y + dy]);
  };

  const onPointerUpHandle = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDrag);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandle);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.setDirty();
  };

  const handleDrag = (newPosition: [number, number]) => {
    onDrag(newPosition);
  };
  // Call the function and destructure the returned values
  //const { rectPoints, extraLines } = generateRectPointsAndLines();

  // Handle Drag Right (Green Line)
  const dragOffsetRight = useRef({ x: 0, y: 0 });

  const onHandleDragRight = (event: PointerEvent) => {
    const [worldX] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dx = worldX - circle.position.x - dragOffsetRight.current.x;
    floorplannerStore.updateSymbolProperty(circle.id, "circleWidth", Math.max(0.1, circleWidth + dx));
    setPivot([-circleWidth / 2, -circleHeight / 2]);
  };

  const onPointerUpHandleRight = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDragRight);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandleRight);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.updateSymbolProperty(circle.id, "circleWidth", circleWidth);
  };

  // Handle Drag Top (Red Line)
  const dragOffsetTop = useRef({ x: 0, y: 0 });

  const onHandleDragTop = (event: PointerEvent) => {
    const [, worldY] = projectToWorld(event.clientX, event.clientY, gl, camera);
    const dy = worldY - circle.position.y - dragOffsetTop.current.y;
    floorplannerStore.updateSymbolProperty(circle.id, "circleHeight", Math.max(0.1, circleHeight + dy * flipY));
    setPivot([-circleWidth / 2, -circleHeight / 2]);
  };

  const onPointerUpHandleTop = () => {
    gl.domElement.removeEventListener("pointermove", onHandleDragTop);
    gl.domElement.removeEventListener("pointerup", onPointerUpHandleTop);
    floorplannerStore.setBlockDirty(false);
    floorplannerStore.updateSymbolProperty(circle.id, "circleHeight", circleHeight);
  };
  // Function to generate 3D points for the circle (adding z = 0 for 3D space)
  const generateCirclePoints = (radius: number, segments: number): [number, number, number][] => {
    const points: [number, number, number][] = [];
    for (let i = 0; i <= segments; i++) {
      const theta = (i / segments) * Math.PI * 2;
      points.push([Math.cos(theta) * radius, Math.sin(theta) * radius, 0]); // x, y, z
    }
    return points;
  };

  useEffect(() => {
    editorStore.updateGroupRef(circle.id, groupRef.current);
  }, [groupRef.current]);

  // Redraw when the symbol.selected changes
  useEffect(() => {
  }, [circle.selected]);

  return (
    <group
      position={[0, 0, (circle.zIndex * zIndexEpsilon)]}
    >
      <DraggableObject
        position={[circle.position.x, circle.position.y]}
        onDragStart={(offset) => {
          setIsDragging(true);
          onDragStart(circle, offset)
        }}
        onDragEnd={(endPosition) => {
          onDragEnd(endPosition)
          setIsDragging(false);
        }}
        onDrag={handleDrag}
        selectable={true}
        attachmentId={circle.id}
        attachmentType="doorAttachments"
        symbol={circle}
      >
        <SelectableSymbol
          handleSize={floorplannerStore.symbolHandleSize}
          calculateBoundingBox={calculateBoundingBox}
          onResize={(newWidth, newHeight, newDepth, handle) => {
            const widthDiff = newWidth - initialWidth.current;
            const heightDiff = newHeight - initialHeight.current;
            floorplannerStore.updateSymbolProperty(circle.id, "circleWidth", newWidth);
            floorplannerStore.updateSymbolProperty(circle.id, "circleHeight", newHeight);
            if (handle === "midRight") {
              circle.position.x += widthDiff / 2;
            } else if (handle === "midLeft") {
              circle.position.x -= widthDiff / 2;
            } else if (handle === "midTop") {
              circle.position.y += heightDiff / 2;
            } else if (handle === "midBottom") {
              circle.position.y -= heightDiff / 2;
            }
            setPivot([-newWidth / 2, -newHeight / 2]);
          }}
          center={centerPosition}
          rotation={circle.rotation}
          symbol={circle}
          drawHandles={true}
          pivot={pivot}
          onPointerOver={() => setIsHovered(true)}
          onPointerOut={() => setIsHovered(false)}
          isDragging={isDragging}
        >
          <group
            ref={groupRef}
            rotation={[0, 0, circle.rotation || 0]}
            onPointerEnter={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.add("cursor-vector");
            }}
            onPointerLeave={() => {
              if (document.body.style.cursor !== "grabbing" && document.body.style.cursor !== "move") document.body.classList.remove("cursor-vector");
            }}
          >
            <mesh scale={[circleWidth / circleHeight, 1, 1]}>
              <circleGeometry args={[circleHeight / 2, 64]} />
              <meshBasicMaterial
    color={circle.fillColor || undefined}
    transparent={true}
    opacity={String(circle.fillColor) === "rgba(0, 0, 0, 0)" ? 0 : 1}
  />
            </mesh>
            <Line
              points={generateCirclePoints(circleHeight / 2, 64)} // Pass generated points for the oval shape
              color={isHovered || circle.selected ? "blue" : lineColor}
              lineWidth={lineWeight}
              scale={[circleWidth / circleHeight, 1, 1]} // Apply scale to the line to match the oval shape
              {...(circle.lineType === "dashed" && {
                dashed: true,
                dashSize: 0.15,
                gapSize: 0.1,
              })}
              raycast={noopRaycast}
            />
          </group>

        </SelectableSymbol>
      </DraggableObject>
    </group>
  );
});

export default CircleSymbol;
