import React from "react";
import { HexColorPicker } from "react-colorful"; // Use HexColorPicker or any other picker
import { IoBanOutline } from "react-icons/io5";

type CustomColorPickerProps = {
  color: string;
  onChange: (newColor: string) => void;
  onTransparentClick: () => void; // New handler for transparency
};

const CustomColorPicker: React.FC<CustomColorPickerProps> = ({
  color,
  onChange,
  onTransparentClick,
}) => {
  return (
    <div
      className="absolute bg-white border rounded z-50 shadow-md ml-2 p-2"
      style={{ top: "100%", left: 0,}}
    >
      {/* Color picker */}
      <HexColorPicker color={color || ""} onChange={onChange} style={{
    width: "190px", // Set desired width
    height: "190px", // Set desired height
    maxWidth: "100%", // Ensure responsiveness
    maxHeight: "100%",
  }}/>
      {/* Transparent option */}
      <div
        style={{
          boxShadow:
            "rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px",
        }}
        className="flex w-full items-center bg-white rounded-b justify-start mt-[6px] py-1 px-4 cursor-pointer"
        onClick={onTransparentClick}
      >
        <IoBanOutline />
        <p className="text-xs">Transparent</p>
      </div>
    </div>
  );
};

export default CustomColorPicker;
